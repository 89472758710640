<template>
  <div class="invoice_content">
    <div class="invoice_con" v-if="allowedInvoiceList.length > 0">
      <div v-for="(item, index) in allowedInvoiceList" :key="index">
        <template v-if="!isApply">
          <van-checkbox v-model="item.flag" shape="square" :checked-color="tool.getThemeList(theme)">
            <div class="invoice_box">
              <div class="left">
                <div class="con">
                  <div class="con_desc">
                    <span class="con_packageName">
                      {{ item.packageName }}
                    </span>
                  </div>
                  <div class="con_date">{{ item.createTime }}</div>
                </div>
              </div>
              <div class="right">
                <div style="display:flex;">
                  <div class="desc_reward">{{ item.billableAmount }}￥</div>
                </div>
                <div class="desc_date">
                  {{
                    item.beginTime
                      ? item.beginTime.slice(0, 10) + "至"
                      : ""
                  }}{{ item.endTime ? item.endTime.slice(0, 10) : "" }}
                </div>
              </div>
            </div>
          </van-checkbox>
        </template>
        <template v-else>
          <div class="invoice_box">
              <div class="left">
                <div class="con">
                  <div class="con_desc">
                    <span class="con_packageName">
                      {{ item.orderType }}
                    </span>
                  </div>
                  <div class="con_date">{{ item.createTime }}</div>
                </div>
              </div>
              <div class="right">
                <div style="display:flex;">
                  <div class="desc_reward">{{ item.orderAmount }}￥</div>
                </div>
                <div class="desc_date">
                  {{
                    item.beginTime
                      ? item.beginTime.slice(0, 10) + "至"
                      : ""
                  }}{{ item.endTime ? item.endTime.slice(0, 10) : "" }}
                </div>
              </div>
          </div>
        </template>
      </div>
    </div>
		<!-- 暂无可开票列表 -->
		<div v-else class="empty_con">
			<div class="empty_invoice">
				<img src="../../assets/image/personal/Invoice.png" alt="">
				<p>暂无可开票列表</p>
			</div>
		</div>
    <div class="con_box">
      <div class="selecctBox">
        <div class="left_s" v-if="!isApply">
          <van-checkbox v-model="allcheck" shape="square" :checked-color="tool.getThemeList(theme)" style="flex-direction: column;">全选</van-checkbox>
        </div>
        <div class="right_r">
          共{{ totalOrder.length }}个订单，{{ count }}元
        </div>
      </div>
      <div>
        <van-button class="button_s" :disabled="!isApply && !Boolean(count)" @click="!isApply ? addInvoiceHandle() : viewInvoiceHandle()"
          >{{buttonText()}}</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
// 需要哪些模块按需引入
import { onMounted, reactive, toRefs, getCurrentInstance, computed } from "vue";
import { useRoute } from "vue-router";
import { setStore } from "@/utils/store";
export default {
	setup(){
		const state = reactive({
			theme: "", //主题名称
      id:"", //开票Id
      h5InvoiceUrl: "", // 发票链接
			page: 1, //页数
			limit: 10, //条数
      isApply: false, //是否已申请
			allowedInvoiceList: [],//默选的可开票列表
			InvoiceListData:[],//最终开票列表
			invoiceTimes:0, // 请求发票次数
		})
		// 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    // 路由 this.$router
    const { $tool: tool, $store: store, $router: router, $http: http } = ctx;
    //调用useRoute,获取页面携带的参数
    const route = useRoute();
    state.id = route.query.id
    if(state.id) state.isApply = true
    else state.isApply = false
		// 全选项
		const allcheck = computed({
			get() {
        //取值
        //every方法，数组中每一项都满足一个条件返回true
        return state.allowedInvoiceList.every(item => item.flag);
      },
      set(newValue) {
        //设置值
        console.log("触发set方法");
        state.allowedInvoiceList.map(item => (item.flag = newValue));
      }
		})
		// 可开票总数
		const totalOrder = computed(()=>{
			return state.allowedInvoiceList.filter(item => item.flag);
		})
		// 可开票金额
		const count = computed(()=>{
			let isCheckedListCount = state.allowedInvoiceList.filter(item => item.flag);
			state.InvoiceListData = isCheckedListCount
			return isCheckedListCount.reduce((total, item) => {
        let totalAmount = ''
        if(state.isApply){
          totalAmount = total + item.orderAmount
        }else{
          totalAmount = total + item.billableAmount
        }
				// 小数点 2位
				let billableAmount = totalAmount.toFixed(2)
				// 取小数点后的数值
				let floor = billableAmount.split('.')[1]
				// 截取最后的位数
				let isZero = Number(floor.slice(1))
				if(!Boolean(isZero)){
					let res = billableAmount.slice(0,billableAmount.length -1 )
					billableAmount = Number(res)
				}else{
					return Number(billableAmount);
				}
				return billableAmount;
			}, 0);
		})
		const methods = {
      // 按钮文本
      buttonText(){
        if(state.isApply) return '查看发票'
        return '去开票'
      },
      // 异步获取发票URL
      getInvoiceUrl(){
        return http.post(`/outputInvoice/getH5InvoiceUrl`, { id: state.id }).then(({ data }) => {
            if (data && data.code === '200') {
              state.h5InvoiceUrl = data.data.h5InvoiceUrl
              ctx.viewInvoiceHandle()
              return
            }
            tool.toast(
              {
                msg: data.msg,
                duration: 1500
              },
              () => {}
            );
          })
      },
      // 查看发票
      async viewInvoiceHandle(){
        if (state.h5InvoiceUrl) {
          location.href = `${state.h5InvoiceUrl}`
          return
        }
		if(state.invoiceTimes >=3) {
			tool.toast({
						msg: `获取发票异常，请联系客服`,
						duration: 2000
					});
			return;
		}
		state.invoiceTimes += 1
        await ctx.getInvoiceUrl()
      },
			// 去开票
			addInvoiceHandle(){
				// 开票限制 
				if(count.value == 0){
					tool.toast({
						msg: `开票金额不能为0元`,
						duration: 2000
					});
					return
				}
				// 保存当前所要开票的数据
				setStore({
					name: "InvoiceListData",
					content: state.InvoiceListData,
					type: "session"
				});
				router.push({
					name:"apply-invoiced",
					query:{
						totalOrder : totalOrder.value,
						count : count.value,
						type : 1,
					}
				})
			},
			// 开票详情
			getViewOutputInvoiceList(id){
				return  http.post(`/outputInvoice/viewOutputInvoice`,{id}).then(({data : res})=>{
					if(res.code == 0){
            state.h5InvoiceUrl = res.data.h5InvoiceUrl
						state.allowedInvoiceList = res.data.invoiceOrderList
						return 
					}
					tool.toast({
						msg: res.msg,
						duration: 1500
					});
				})
			},
      // 可开发票列表
      getAllowedInvoiceList(){
				return  http.get(`/invoice/allowedInvoiceList`,{
					params: {
						iccid : store.state.user.userInfo.iccid,
						page: state.page,
						limit: state.limit
					}
				}).then(({data : res})=>{
					if(res.code == 0){
						state.allowedInvoiceList = res.data
						return 
					}
					tool.toast({
						msg: res.msg,
						duration: 1500
					});
				})
			}

		};
		onMounted(async () => {
			//获取主题名称
      state.theme = store.state.common.theme;
      if(state.id) await ctx.getViewOutputInvoiceList(state.id)
      else await ctx.getAllowedInvoiceList() 
    	state.allowedInvoiceList.map(item => (item.flag = true));
		});
    return { ...toRefs(state), ...methods, tool, allcheck, totalOrder, count };
	} 
};
</script>

<style lang="scss" scoped>
@import "@/style/theme/index";
.invoice_content {
	position: relative;
	.invoice_con,.empty_con{
		background: #ffffff;
		border-radius: 0px 0px 20px 20px;
		margin: 0 auto;
		padding: 16px 30px 0;
		height: calc(98vh - 118px);
		overflow-y: auto;
	}
	.empty_con{
		.empty_invoice{
			position: absolute;
			text-align: center;
			margin: auto;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}
	.invoice_box {
		display: flex;
		justify-content: space-between;
		.left {
			display: flex;
			div img {
				width: 48px;
				height: 48px;
			}
			.con {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				padding-left: 10px;
				.con_desc {
					font: {
						size: 30px;
						weight: 400;
					}
					color: #333333;
					.con_packageName {
						width: 300px;
						white-space: nowrap;
						overflow-x: auto;
						display: inline-block;
						position: relative;
						top: 8px;
					}
				}
				.con_date {
					font: {
						size: 22px;
						weight: 400;
					}
					color: #aaaaaa;
				}
			}
		}
		.right {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-end;
			margin-left: 6px;
			.desc_reward {
				font-size: 36px;
				color: #fc992c;
			}
			.desc_date {
				width: 270px;
				white-space: nowrap;
				overflow-x: auto;
				font: {
					size: 22px;
					weight: 400;
				}
				color: #aaaaaa;
			}
		}
	}
	.con_box {
		width: 100%;
		display: flex;
		margin-top: 20px;
		justify-content: space-around;
		align-items: center;
		.selecctBox {
			display: flex;
			align-items: center;
			.left_s {
				width: 120px;
				border-right-color: grey;
				border-right-style: solid;
				border-right-width: 1px;
				padding-right: 16px;
			}
			.right_r {
				width: 250px;
				word-break: break-all;
				padding-left: 10px;
			}
		}
		.button_s {
			color: #fff;
			width: 300px;
			@include background_color("background_color1");
		}
	}
}
.invoice_con{
	/deep/.van-checkbox__label{
		line-height: 48px;
		margin-left: 40px;
	}
	/deep/.van-checkbox__icon{
		position: relative;
		bottom: 20px;
	}
}
.left_s{
	/deep/.van-checkbox__label{
		margin-left: 0px;
	}
}
</style>
